import React from "react";

function Footer() {
  return (
    <footer className="border-top">
      <div class="container p-3 pt-5">
        <div class="row">
        <div class="col-lg-3">
            <p class="save">
              لا يوجد حقوق على أي مادة مستخدمة في الموقع، يمكنك إعادة استخدامها
              كما تشاء بدون الرجوع إلينا.
            </p>
          </div>
       
          <div class="col-lg-3 text-center">
          <div class="list">
              <h5 class="title-footer">الأذكار</h5>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a href="azkar morning/azkar.html" class="nav-link  m-0 pb-0">
                    أذكار الصباح
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="azkar evening/azkar.html"
                    class="nav-link  m-0 pb-0 "
                  >
                    أذكار المساء
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link  m-0 pb-0">
                    اذكار اخري وادعية اخري
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link  m-0 ">
                    الرقية الشرعية
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 text-center">
          <div class="list">
              <h5 class="title-footer">الصفحات الرئيسية</h5>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a href="../index.html" class="nav-link  m-0 pb-0">
                    الصفحة الرئيسية
                  </a>
                </li>
                <li class="nav-item">
                  <a href="quran/المصحف.html" class="nav-link  m-0 ">
                    القرأن
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 text-center">
            <div class="list">
              <h5 class="title-footer">قد يهمك</h5>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a href="#" class="nav-link  m-0 pb-0">
                    إبلاغ عن مشكلة او إضافة تحديث
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
