import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllSurah, getSurah } from "../redux/actions/MuslimAction";
import React, { useEffect, useState } from "react";

function Read() {
  const [ayahs, setAyahs] = useState([]);
  let { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.surah);

  useEffect(() => {
    dispatch(getSurah());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.length > 0) {
      setAyahs(data[id - 1]);
    }
  }, [data]);
  console.log(ayahs.verses);
  return (
    <div className="container mt-4">
      <h3 className="name text-center">سورة {ayahs.name}</h3>
      <div id="quran" className=" p-3 mt-4">
        <p id="text2">
          {ayahs.verses &&
            ayahs.verses.map((verse) => (
              <React.Fragment key={verse.id}>
                <span className="quran">{verse.text}</span>
                <span className="icon mx-3">{` \ufd3f${verse.id.toLocaleString(
                  "AR-EG"
                )}\ufd3e `}</span>
              </React.Fragment>
            ))}
        </p>
      </div>
    </div>
  );
}

export default Read;
