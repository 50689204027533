import React from "react";
import TitleSection from "./TitleSection";

function AzkarSection() {
  return (
    <div class="azkar-section">
      <TitleSection name={"الأذكار"} />
      <div class="row">
        <div class="col-12 mb-3 col-lg-4">
          <a
            href="/azkar morning/"
            class="card p-2 morning"
            style={{
              backgroundImage:
                "url(https://c4.wallpaperflare.com/wallpaper/772/265/137/2d-flat-nature-mountain-top-hd-wallpaper-preview.jpg)",
            }}
          >
            <div class="card-body me-auto">
              <p class="card-title h3">
                أذكار الصباح
              </p>
            </div>
          </a>
        </div>

        <div class="col-12 mb-3 col-lg-4">
          <a href="/azkar evening/" class="card p-2 evening"  style={{
              backgroundImage:
                "url(https://e1.pxfuel.com/desktop-wallpaper/351/721/desktop-wallpaper-flat-2d.jpg)",
            }}>
            <div class="card-body me-auto">
              <p class="card-title h3">
                أذكار المساء
              </p>
            </div>
          </a>
        </div>
        <div class="col-12 mb-3 col-lg-4">
          <a href="/azkar/roqia" class="card p-2 roqia" style={{
              backgroundImage:
                "url(https://static5.depositphotos.com/1000875/537/v/450/depositphotos_5373161-stock-illustration-rain-vector-image-with-dark.jpg)",
            }}>
            <div class="card-body me-auto">
              <p class="card-title h3">
                الرقية الشرعية
              </p>
            </div>
          </a>
        </div>
      </div>
      <p class="text-center info mt-3">لا تنسَ الدعاء لي بظهر الغيب.</p>
    </div>
  );
}

export default AzkarSection;
