import { AllSurah, azkarMorning, quotes, surah } from "../types/Types";
import azkars from "../Api/azkar.json";
import quotesJson from "../Api/Quotes.json";
import quran from "../Api/Quran.json";

export const getAllSurah = () => {
  return {
    type: AllSurah,
    data: quran,
  };
};

export const getSurah = () => {
  return {
    type: surah,
    data: quran,
  };
};

export const getAzkarMorning = () => {
  return {
    type: azkarMorning,
    data: azkars.slice(0, 30),
  };
};

export const getAzkarEvening = () => {
  return {
    type: azkarMorning,
    data: azkars.slice(31, 60),
  };
};

export const Quotes = () => {
  return {
    type: quotes,
    data: quotesJson,
  };
};
