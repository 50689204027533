import { useDispatch, useSelector } from "react-redux";
import Surah from "../Components/Surah/Surah";
import { useEffect, useState } from "react";
import { getAllSurah } from "../redux/actions/MuslimAction";

function Quran() {
  const [surahs, setSurahs] = useState([]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.AllSurah);

  useEffect(() => {
    dispatch(getAllSurah());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.length > 0) {
      setSurahs(data);
    }
  }, [data]);

  return (
    <div className="content">
      <div className="container mt-4">
        <div className="row justify-content-center" id="surahs">
          {surahs.map((surah) => (
            <div className="col-lg-3" key={surah.id}>
              <Surah
                title={surah.name}
                id={surah.id}
                ayasNumber={surah.total_verses}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Quran;
