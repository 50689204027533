import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./Components/Utils/Header/Header";
import Quran from "./pages/Quran";
import Read from "./pages/Read";
import AzkarMornig from "./pages/AzkarMorning";
import AzkarEvening from "./pages/AzkarEvening";
import Footer from "./Components/Utils/Footer/Footer";
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/quran" element={<Quran />}></Route>
        <Route path="/surah/:id" element={<Read />}></Route>
        <Route path="/AzkarMornig" element={<AzkarMornig />}></Route>
        <Route path="/AzkarEvening" element={<AzkarEvening />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
