import { AllSurah, azkarMorning, quotes, surah } from "../types/Types";

const initialState = {
  AllSurah: [],
  surah: [],
  azkar: [],
  quotes: [],
};

export const MuslimReducer = (state = initialState, action) => {
  switch (action.type) {
    case AllSurah:
      return {
        ...state,
        AllSurah: action.data,
      };
    case surah:
      return {
        ...state,
        surah: action.data,
      };
    case azkarMorning:
      return {
        ...state,
        azkar: action.data,
      };
    case quotes:
      return {
        ...state,
        quotes: action.data,
      };
    default:
      return state;
  }
};
